/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@2.2.4/dist/jquery.min.js
 * - /npm/jquery-ui-dist@1.13.3/jquery-ui.min.js
 * - /npm/jquery-backstretch@2.1.18/jquery.backstretch.min.js
 * - /gh/kirkas/Ascensor.js@1.8.21/dist/jquery.ascensor.min.js
 * - /npm/jquery-tooltipster@3.3.0/js/jquery.tooltipster.min.js
 * - /npm/imagesloaded@5.0.0/imagesloaded.pkgd.min.js
 * - /npm/wookmark@2.2.0/wookmark.min.js
 * - /gh/malihu/malihu-custom-scrollbar-plugin@3.1.5/jquery.mCustomScrollbar.min.js
 * - /npm/lightgallery@1.7.3/src/js/lightgallery.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
